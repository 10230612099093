// styles.scss
@import 'navigation';
@import 'search';

button:disabled {
  cursor: not-allowed;
  opacity: 0.5;
  pointer-events: none;
}

.button--destructive {
  background-color: rgb(220, 72, 71)!important;
  color: rgb(255, 255, 255)!important;
}

.button--destructive:hover {
  background-color: rgb(192, 39, 37)!important;
}

.button--destructive:active {
  background-color: rgb(192, 39, 37)!important;
}

[class^="css-"][class*="Columns"] {
  justify-content: space-between;
}

input[name="patient-searchbox"] {
  width: 300px;
  min-width: 120px;
}

@media (max-width: 650px) {
  input[name="patient-searchbox"] {
    width: calc(300px - (650px - 100vw));
  }
}
