.tabItem__header--disabled {
  // Your disabled styles here
  cursor: not-allowed;
  opacity: 0.5;
  pointer-events: none;
}

.tabItem__header {
  font-size: 16px;
  line-height: 16px;
}
