.search__suggestions--container {
  position: absolute;
  top: 100%;
  left: 50%;
  width: calc(min(600px, 100vw - 100px));
  transform: translateX(-50%) translateY(2em);
  z-index: 1000;
}

input[name="header-searchbar"] {
  width: 500px;
  min-width: 120px;
}

.search-wrapper {
  position: relative;
  display: inline-block
}

@media (max-width: 750px) {
  input[name="header-searchbar"] {
    width: calc(500px - (750px - 100vw));
  }
}
